import { useAuth0 } from "@auth0/auth0-react";

function HomePage() {
	return (
	<>
		<div className='banner'>
			<h1>
				What will we <b className="primary"><i>learn</i></b> today?
			</h1>
			<p>
				Grab quick info on monsters and spells, get insights into monster stats trends, and discover your character's damage potential!
			</p>
		</div>
	</>
	);
}

export default HomePage;
