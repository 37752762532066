import '../styles/NavUser.sass';
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function NavUser () {
	const { user: auth0user, logout } = useAuth0();
	const { data: user_list, isLoading } = useQuery({
		queryKey: ['users'],
		queryFn: () => axios.get('/user').then(x => x?.data),
		staleTime: 60 * 1000 * 10,
	});
	const mutation = useMutation({mutationFn: () => axios.post('/user'), gcTimer: Infinity, mutationKey: ['users']});
	if(isLoading)
		return <div>Loading...</div>
	if(user_list?.find(x => x.id === auth0user['dnd.tvonsegg.com/user']?.id) === undefined)
		mutation.mutate({});
	return (
		<div className="profile-nav">
			<img src={auth0user.picture} alt="Profile" className="round" />
			<div className='button_group'>
				<button className="non-primary" onClick={() => logout()}>logout</button>
				<button className="non-primary"><Link to="/profile">profile</Link></button>
			</div>
		</div>
	);
}
