import './styles/Tabs.sass';
import { useId } from 'react';

export default function Tabs(tabs) {
	const id = useId();
	let keys = Object.keys(tabs);
	let inputs = keys.map((_, i) => 
		<input key={id + i.toString()} id={id + i.toString()} name={"tab_selector_"+id} defaultChecked={i === 0} type="radio" />
	);
	let labelLi = keys.map((k, i) => 
		(<li key={id + i.toString()}><label htmlFor={id+i.toString()}>{k}</label></li>)
	);
	let contentLi = keys.map((k, i) => 
		(<li key={id + i.toString()}>{tabs[k]}</li>)
	);
	return (
		<div className='tabs'>
			{inputs}
			<ul>
				{labelLi}
			</ul>
			<ul>
				{contentLi}
			</ul>
		</div>
	);
}
