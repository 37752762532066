import { useState, useRef } from 'react';

function toggleDialog(dialogRef) {
	if(dialogRef.current === null || dialogRef.current === undefined)
		return;
	if(dialogRef.current.hasAttribute('open'))
		dialogRef.current.close();
	else {
		dialogRef.current.showModal();
		// hack because we want to select the first thing with autoFocus...
		// initial rendering might not have the dialog populated.
		setTimeout(() => {
			let inputs = dialogRef.current.querySelectorAll('input');
			if(inputs === undefined || inputs.length === undefined || inputs.length < 1){
				return;
			}
			inputs[0].focus();
		}, 1);
	}
}

export function useModal(contentFn, cb) {
	let dialogRef = useRef(null);
	let [state, setState] = useState(undefined);
	let toggle = () => toggleDialog(dialogRef);
	let cb2 = (arg) => {(cb ?? (() => {}))(arg ?? state); toggle();};
	return [(
		<dialog ref={dialogRef} onMouseDown={toggle} style={{padding:0}}>
			<form onMouseDown={(e) => e.stopPropagation()} method="dialog">
				<a className="close" onMouseDown={() => toggle()}/>
				{contentFn(state, setState, cb2)}
			</form>
		</dialog>
	), (s) => {setState(s); toggle();}];
}
