import axios from 'axios';
import { useModal } from '../UseModal';
import { useUserStore } from '../UseUserStore.js';

function InviteModal(state, setState, toggle) {
	const onSubmit = () => {
		if(!state || !state.email)
			return toggle();
		axios.post('/user/invite', state).then(() => toggle());
	};
	return (
	<>
		<label>invite email<input type='text' onChange={(e) => setState(e.target.value)}/></label>
		<div className='button_group'>
			<button onMouseDown={() => onSubmit()}>Send invite</button>
		</div>
	</>)
}

export default function ProfilePage() {
	const [inviteElement, toggleInvite] = useModal(InviteModal, () => {
	});
	const { data, mutate } = useUserStore('user', 'profile', {});
	return (
		<>
			<button onMouseDown={() => toggleInvite()}>Invite somebody via email?</button>
			{inviteElement}
		</>
	);
}
